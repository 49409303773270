<template>
    <section class="section5-container">
        <div class="title-container">
            <h2 class="title-h1 text-center">Nuestra experiencia nos avala</h2>
            <p class="text-center p-title">Más de 5 años ayudando a nuestros clientes</p>
        </div>
        <div id="clientes"
             class="row justify-content-center p-2">
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-2 item ">
                <h3 class="h3-text">+90,000</h3>
                <h4 class="h4-text">Clientes atendidos</h4>
            </div>
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-2 item special">
                <h3 class="h3-text">+ $500 M</h3>
                <h4 class="h4-text">en préstamos otorgados</h4>
            </div>
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-2 item ">
                <h3 class="h3-text">+1,500</h3>
                <h4 class="h4-text">Solicitudes diarias</h4>
            </div>
        </div>
    </section>

</template>

<script>


export default {
    name: "Section5S1",
    components: {
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
                initialSlide: 0
            },
            comentarios: [
                { id: 0, title: "María Sandoval", comentario: "Gestión eficiente y rápida, Amabilidad y claridad de los ejecutivos, trato muy amable del servicio al cliente. Los recomendaria para resolver una situación urgente.", imagen: "require('@/assets/img/avatar1.webp')" },
                { id: 1, title: "Daniel Hernández", comentario: "Estoy muy contento. Un servicio de atencion al cliente excelente, y mi pago mensual me parecio muy justo.", imagen: "avatar2.webp" },
                { id: 2, title: "Paulina Soto", comentario: "Me ayudaron con todo a través de WhatsApp y no tuve que pagar ningun cargo extra al final.", imagen: "avatar3.webp" }
            ],
            prensa: [
                { imagen: "logo-libreinversion.webp" },
                { imagen: "logo-libreinversion.webp" },
                { imagen: "logo-libreinversion.webp" },
            ]
        }

    },
    mounted() {
    },
    methods: {

    }
};
</script>

<style scoped lang="scss">


// Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.section5-container {
    padding: 2rem 0rem;
}

.title-container {
    margin-bottom: 2rem;
}

@include media-breakpoint-down(xs){
    .title-container {
    margin: 0px 13px 23px 13px;
    }
}

.h3-text {
    color: #004680;
    text-align: center;
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    font-style: normal;
}

.h4-text {
    color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 14px;
        font-style: normal;
}

#clientes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0rem 2.5rem 0rem 2.5rem !important;
}

.item {
        fill: rgba(255, 255, 255, 0.57);
        stroke-width: 1.4px;
        stroke: #004680;
        // filter: drop-shadow(0px 2.801px 2.101px rgba(0, 0, 0, 0.25));
        border: 2px solid #006480;
        border-radius: 1rem;
        -webkit-box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 13px 5px -6px rgba(0, 0, 0, 0.75);
    }

.item {
    fill: rgba(255, 255, 255, 0.57);
    stroke-width: 1.4px;
    stroke: #004680;
    border: 2px solid #006480;
    border-radius: 1rem;
    padding: 1rem !important;
    width: 85%;

    .h3-text {
        font-size: 2rem;
    }

    .h4-text {
        font-size: 1.1rem;
    }
}

.item.special {
    width: 100%;

    h3 {
        font-size: 2.7rem;
    }
}

.section5-container div {
    text-align: center;

    .title-h1 {
        color: #004680;
        /* WEB 1440 px/H2 */
        font-family: 'Montserrat-Bold';
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 0;
        font-size: 31px;
    }

    .p-title {
        color: #004680;
        font-family: 'Montserrat';
        font-weight: 600;;
        font-size: 22px;
    }
}

/* purgecss ignore */
#opiniones {
    .card {
        z-index: -1;
        width: 20rem;
        height: 16rem;
    }
}

.section5-container {
    background-color: #FFA50008;
}

#desktop {
    .card {
        z-index: -1;
        width: 19rem;
        height: 16rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

#prensa {
    .card {
        z-index: -1;
        // box-shadow: none;
        // border: none;
    }
}

#socios {
    .card {
        z-index: -1;
        // box-shadow: none;
        // border: none;
    }
}

.background-gray {
    background-color: #f8f8f8;
}

@include media-breakpoint-between(xs, sm) {
    .title-h1 {
        font-size: 1rem;
        font-weight: bold;
        margin-left: 5%;
        margin-right: 5%;
    }

    #opiniones {
        .card {
            width: 17rem;
            height: 15rem;
            font-size: 1.8vh;
            font-size: 0.85rem;
        }
    }

    .padding {
        padding: 1.5rem;
    }

    #desktop {
        display: none !important;
    }

    img {
        filter: grayscale(0);
    }

    #carousel {
        #mobile {
            .card {
                border: none;
                margin-left: 12.5%;
                margin-right: 12.5%;
                height: auto;
                width: 75%;
            }
        }
    }

    .card-img-top {
        border: none;
    }

}

@include media-breakpoint-between(md, lg) {
    
    .section5-container {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .item {

    .h3-text {
        font-size: 2.7rem;
    }

    .h4-text {
        font-size: 1.7rem;
    }
}

    .title-h1 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    #desktop {
        display: none !important;
    }

    .padding {
        padding: 0;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    #prensa {
        img {
            filter: grayscale(100);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #socios {
        img {
            filter: grayscale(100);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #carousel {
        #mobile {
            .card {
                border: none;
                margin-left: 25%;
                margin-right: 25%;
                height: auto;
                width: 50%;

                img {
                    filter: grayscale(100);
                }
            }
        }
    }
}

@include media-breakpoint-between(xl, xxl) {

    .title-h1 {
            font-size: 31px !important;

        }

    .p-title {
        color: #004680;
        font-family: 'Montserrat';
        font-size: 22px !important;
    }

    #clientes {
        flex-direction: row;

        >div:nth-child(2) {
            height: 12rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-height: 10.7rem;
        }
    }

    #mobile {
        display: none !important;
    }

    .padding {
        padding: 2.5rem;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    #prensa {
        img {
            filter: grayscale(100);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #socios {
        img {
            filter: grayscale(100);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    .special {
        max-width: 22rem;
    }
}

@include media-breakpoint-down(sm){
    .title-h1{
        font-size: 20px !important;
    }

    .p-title{
        font-size: 16px !important;
    }
}
</style>