<template>
  <div id="navigation_menu" >
    <!-- OPCIONES DE MENU DESPLEGABLE CUANDO ESTÁ EN MODO MOBILE -->
    <nav id="navbar_main" v-if='!preload' class="mobile-offcanvas navbar navbar-expand-lg navbar-dark bg-light" >
      <!-- X para cerrar menu mobile -->
      <div class="row justify-content-center align-items-center">
        <div class="col-10 text-center">
          <img id="ibancarLogo" style="width: 100px;" src="@/assets/img/ibancar_azul.svg" alt="Ibancar" />
        </div>
        <div class="offcanvas-header col-2">
          <button @click="close_offcanvas(null)" class="btn-close float-end" aria-label="Close button"></button>
        </div>
      </div>
      <!-- Desplegable con las opciones de navegación del menú mobile -->
      <div class="d-flex flex-row justify-content-center">
        <div class="text-center">
          <div class="pt-4 pb-2 " @click="close_offcanvas('/como-funciona')">
            <p class="h4" >¿Cómo funciona?</p>
          </div>
          <div class="pt-4 pb-2 " @click="close_offcanvas('/prestamo-garantia-auto')">
            <p class="h4" >Préstamo con garantía de auto</p>
          </div>
          <div class="pt-4 pb-2 " @click="close_offcanvas('/quienes-somos')">
            <p class="h4" >¿Quienes somos?</p>
          </div>
          <div class="pt-4 pb-2 " @click="close_offcanvas('/contacto')">
            <p class="h4" >Contacto</p>
          </div>
          <div class="pt-4 pb-2 dropdown-divider d-flex justify-content-center" @click="close_offcanvas('/contacto/#contacta')">
            <a class="grey mr-3" href="tel:+525555661583" >
              <img class="image-icon-2" src="@/assets/img/phone-icon-blue.svg"  alt="Ibancar phone"/>
            </a>
            <a class="grey mr-3 d-flex mt-1 align-items-center" href="tel:+525555661583" >
              <p class="h4 mb-0">
                55 5566 1583
              </p>
            </a>
            <a class="grey" target="_blank" href="https://api.whatsapp.com/send?l=es&phone=5215612673878&text=¡Hola!%20Solicito%20información%20sobre%20el%20préstamo%20con%20garantía%20de%20auto.%20Gracias." >
              <img class="image-icon" src="@/assets/img/whatsapp_full_icon.svg"  alt="Ibancar phone">
            </a>
          </div>
          <div class="pt-4 pb-2" @click="close_offcanvas('/contacto/#enviarMensaje')">
            <a class="grey" href="mailto:hola@ibancar.mx">
              <img width="35"  class="" src="@/assets/img/email-icon-blue.svg"  alt="Ibancar email"/>
              <span class="h4" >
                hola@ibancar.mx
              </span>
            </a>
          </div>
          <div class="pt-4 pb-2 d-flex justify-content-center align-items-center mb-4" @click="close_offcanvas('/zona-usuarios')">
            <a class="grey rounded-pill py-2 px-4 blue-border btn" href="https://clientes.ibancar.mx/" target="_blank" rel="nofollow noopener">
            <p class="h4 mb-0" >
                Área clientes
              </p>
            </a>
          </div>
        </div>
      </div>
    </nav>
    <!-- Fin Navegacion menú mobile -->

    <!-- OPCIONES DE MENU DESKTOP  -->
    <nav id="desktop"  v-if='!preload' role="navigation" style="background-color: #fff !important; padding:0;">
      <div class="top-banner-profeco" v-if="windowWith <= 768"><p>¡Estamos registrados ante <b class="profeco">PROFECO</b>!</p></div>
      <div class="d-flex flex-row pt-2 pb-2 pt-sm-3 pb-sm-3 pt-md-3 pb-md-3 pt-lg-4 pb-lg-4 navcontainer justify-content-between justify-content-sm-between justify-content-md-between justify-content-lg-between justify-content-xl-around align-items-center">
        <!-- ZONA LOGO -->
        <div id="logoMenu" class="">
          <router-link to="/">
            <img height="59" width="228" id="ibancarLogo" class="img-fluid" src="@/assets/img/ibancar_azul.svg" alt="Ibancar" />
          </router-link>
        </div>
        <!-- OPCIONES DE MENU EN TOP NAVBAR -->
        <div class="top-navbar-container">
          <!-- Opciones que sólo aparecerán en monitores y desktop -->
          <div id="opciones_desktop" :style="{'margin-top': windowWith >= 1200  ? '10px' : '0'}">
            <div class="d-flex align-items-center justify-content-center">
              <div class="options-desktop d-flex align-items-center justify-content-around" style="max-width: 880px; width: 90%;">
                <div class="desktop-option "><router-link to="/como-funciona">¿Cómo funciona?</router-link></div>
              <div class="desktop-option" ><router-link to="/quienes-somos">¿Quiénes somos?</router-link></div>
              <!-- <div v-else class="col-3 col-xxl-4 pl-4 col-lg-4" ><router-link to="/prestamo-garantia-auto">¿Quiénes somos?</router-link></div> -->
              <div class="desktop-option"><router-link to="/contacto">Contacto</router-link></div>
              <div v-if="windowWith >= 1440" class="desktop-option "><img src="@/assets/img/home/phone_icon.png" alt="icono telefonico" class="phone_icon"><a href="tel:+525555661583" style="color: #004680"><strong>+55 5566 1583</strong></a></div>
              </div>
            </div>
            <ul class="d-md-none">
              <li><router-link to="/" >Inicio</router-link></li>
              <li><router-link to="/como-funciona">¿Cómo funciona?</router-link></li>
              <li><router-link to="/prestamo-garantia-auto">Préstamo con garantía de auto</router-link></li>
              <li><router-link to="/quienes-somos">¿Quiénes somos?</router-link></li>
              <li><router-link to="/contacto">Contacto</router-link></li>
            </ul>
          </div>
          <!-- Fin opciones desktop  -->
          <!-- Opciones que sólo apareceran en Tablets y Móviles -->
          <div id="zona_hamburger">
            <ul class="d-flex flex-row align-items-center">
              <li>
                <a href="tel:+525555661583" aria-label="número de teléfono">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="14" fill="#004680" />
                    <path d="M12.9879 9.96869L10.6198 7.2352C10.3468 6.92021 9.84635 6.92161 9.52925 7.2394L7.58186 9.19029C7.00226 9.77059 6.83636 10.6323 7.17166 11.3232C9.17478 15.4706 12.5197 18.8202 16.6643 20.8291C17.3545 21.1644 18.2155 20.9985 18.7951 20.4182L20.7607 18.4491C21.0792 18.1306 21.0799 17.6273 20.7621 17.3543L18.0181 14.9989C17.7311 14.7525 17.2852 14.7847 16.9975 15.0731L16.0427 16.0293C15.9938 16.0805 15.9295 16.1143 15.8596 16.1254C15.7896 16.1365 15.718 16.1244 15.6556 16.0909C14.0949 15.1921 12.8003 13.8958 11.9036 12.334C11.8701 12.2715 11.8579 12.1997 11.869 12.1297C11.8801 12.0596 11.9139 11.9952 11.9652 11.9462L12.9172 10.9935C13.2056 10.7037 13.2371 10.2557 12.9879 9.96799V9.96869Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
              </li>
              <li class="">
                <router-link to="/contacto/#enviarMensaje" aria-label="enviar mensaje">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="14" fill="#004680" />
                    <path d="M20.5 9H7.5C7.22344 9 7 9.20312 7 9.45455V18.5455C7 18.7969 7.22344 19 7.5 19H20.5C20.7766 19 21 18.7969 21 18.5455V9.45455C21 9.20312 20.7766 9 20.5 9ZM19.875 10.5739V17.9773H8.125V10.5739L7.69375 10.2685L8.30781 9.55114L8.97656 10.0241H19.025L19.6938 9.55114L20.3078 10.2685L19.875 10.5739ZM19.025 10.0227L14 13.5739L8.975 10.0227L8.30625 9.54972L7.69219 10.267L8.12344 10.5724L13.4609 14.3452C13.6144 14.4536 13.8033 14.5124 13.9977 14.5124C14.192 14.5124 14.3809 14.4536 14.5344 14.3452L19.875 10.5739L20.3062 10.2685L19.6922 9.55114L19.025 10.0227Z" fill="white" />
                  </svg>
                </router-link>
              </li>
              <li>
                <a href="https://clientes.ibancar.mx/" target="_blank" rel="nofollow noopener" aria-label="área de clientes">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="13" fill="#fff" stroke="#004680" stroke-width="1" />
                    <path d="M14.2333 14.1384C16.2045 14.1384 17.8025 12.5404 17.8025 10.5692C17.8025 8.59799 16.2045 7 14.2333 7C12.2621 7 10.6641 8.59799 10.6641 10.5692C10.6641 12.5404 12.2621 14.1384 14.2333 14.1384Z" stroke="#004680" />
                    <path d="M17.8042 15.5664H18.0555C18.5774 15.5666 19.0812 15.7573 19.4724 16.1027C19.8636 16.4481 20.1152 16.9245 20.1799 17.4424L20.459 19.6724C20.4841 19.8733 20.4662 20.0773 20.4064 20.2707C20.3467 20.4641 20.2465 20.6427 20.1125 20.7944C19.9785 20.9462 19.8137 21.0677 19.6292 21.1509C19.4446 21.2342 19.2445 21.2772 19.042 21.2771H9.42799C9.22554 21.2772 9.02538 21.2342 8.84082 21.1509C8.65626 21.0677 8.49151 20.9462 8.3575 20.7944C8.22349 20.6427 8.1233 20.4641 8.06356 20.2707C8.00382 20.0773 7.98591 19.8733 8.01102 19.6724L8.28942 17.4424C8.35416 16.9243 8.60595 16.4477 8.99743 16.1022C9.38891 15.7568 9.89312 15.5662 10.4152 15.5664H10.6658" stroke="#004680" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
              </li>
              <li>
                <button @click="triggered($event)" data-trigger="navbar_main" class="d-xl-none navbar-toggler" type="button" aria-label="Toggle navigation">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.08645V0.223633H24V4.08645H0ZM0 13.7435H24V9.88069H0V13.7435ZM0 23.4005H24V19.5377H0V23.4005Z" fill="#004680" />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
          <!-- Fin opciones mobile -->
          <!-- Zona usuario solo visible cuando entra en modo Monitor Desktop -->
        </div>
        
        <div id="zona_usuarios" :style="{'margin-top': windowWith >= 1200  ? '5px' : '0'}">
          <a href="https://clientes.ibancar.mx/" target="_blank" 
          class="btn btn-outline-warning my-2 my-sm-0" style="font-family: 'Montserrat-Bold';" role="button" 
          aria-pressed="true" rel="nofollow noopener" data-v-046d3531="">
          Área privada &nbsp;&nbsp;&nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
            <path d="M1 1L9 9L1 17" stroke="#004680" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </a>
        </div>
        
      </div>
    </nav>
  </div>
</template>

<script>
window.addEventListener("scroll", () => {
  let navbar = document.getElementById("desktop");
  const scrollY = window.scrollY;
  const screen_width = window.screen.width;
  if (screen_width >= 768 && scrollY > 0) {
    navbar.className = "transition_forward";
  } else {
    navbar.className = "transition_backward";
  }
});
import store from "@/store";
import { mapState } from "vuex";
export default {
  name: "NavBar",
  emits: ["clicked"],
  el: "#navigation_menu",
  data() {
    return {
      windowWith: window.innerWidth
    }
  },
  store,
  computed: mapState({
    preload: (state) => state.preload,
  }),
  methods: {
    triggered(e) {
      this.$emit("clicked", e.target);
    },
    close_offcanvas(route) {
      let mobile_canvas = document.querySelector(".mobile-offcanvas.show");
      this.$parent.darken_screen(false);
      mobile_canvas ? mobile_canvas.classList.remove("show") : "";
      document.body.classList.remove("offcanvas-active");
      route ? this.$router.push({ path: route }) : null;
    },
    getWindowWidth() {
      this.windowWith = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
  },
  watch: {
    windowWith(value) {
      return value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "@/assets/styles/variables.scss";


@include media-breakpoint-only(lg){

.navcontainer{
  margin: 0rem 2rem;
}
}

@include media-breakpoint-up(xl){

  .navcontainer{
    margin: 0rem 4rem;
  }
}

.navigation-menu {
  overflow: hidden;
}

.profeco {
  font-family: 'Montserrat-Bold';
  font-weight: bold;
}

// clases comunes a todas los tamaños
.image-icon{
  height: 1.5rem;
}
.image-icon-2{
  height: 1.2rem;
}
#navbar_main {
  background-color: #fff !important;
  margin: 0px 0px;
  border: 3px solid $blue;
  border-radius: 20px;
  font-family: 'Montserrat-Bold';
  width: 280px;
}

.blue-border {
  border: solid 1.5px $blue;
}

.phone_icon{
  width: 1.5rem;
  height: 1.5rem;
}

.top-banner-profeco{
  background-color: #D8D8D8;
  color: #004680;
  display: flex;
  align-items:center;
  justify-content:center;
  padding:0.3rem 0.3rem;

  p{
    margin-bottom:0;
    font-family: 'Montserrat';
    font-size: 0.8rem;
  }
}

#opciones_mobile {
    font-family: 'Montserrat';
    font-weight: 600;
    
    color: $blue;

    a:link {
      color: $blue;
      text-decoration: none;
    }
    a:visited {
      color: $blue;
      text-decoration: none;
    }
    a:hover {
      color: $orange;
      text-decoration: none;
    }
    a:active {
      color: $orange;
      text-decoration: none;
    }
}

#opciones_desktop {
    font-family: 'Montserrat';
    font-weight: 600;

    color: $blue;

    a {
      font-size: 18px;
    }
    a:link {
      color: $blue;
      text-decoration: none;
    }
    a:visited {
      color: $blue;
      text-decoration: none;
    }
    a:hover {
      color: $orange;
      text-decoration: none;
    }
    a:active {
      color: $orange;
      text-decoration: none;
    }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: $blue !important;
  background: transparent url("../assets/img/close.svg") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.dropdown-divider {
  height: auto;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 2px solid #D8D8D8;
}

.grey {
  color: #333;
}

@include media-breakpoint-between(xs, md) {
  #desktop {
    background-color: #fff;
  }
  .offcanvas-header {
    display: block;
  }
  .mobile-offcanvas {
    visibility: unset;
    transform: translateX(100%);
    border-radius: 0 0 0 10px;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: auto;
    width: auto;
    z-index: 1200;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }
  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid {
    display: block;
  }
  #logoMenu {
    padding-left: 25px;
  }
  #opciones_desktop {
    display: none;
    visibility: hidden;
    width: 100%;
  }
  #zona_usuarios {
    display: none;
    visibility: hidden;
  }

  .h4 {
    color: $blue !important;
    font-size: 0.95rem;
    &.router-link-exact-active {
      color: $blue !important;
      font-weight: bold;
    }
  }
  span:hover {
    cursor: pointer;
  }
}
@include media-breakpoint-up(lg) {
  .navbarcontainer {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .btn-outline-warning{
    margin-right: 0 !important;
  }
}

// Pantallas medianas a Grandes 767px a 991px
@include media-breakpoint-down(md) {
  @keyframes fadeToWhite {
    from {
      background-color: transparent;
    }
    to {
      background-color: #fff;
    }
  }

  @keyframes fadeToTransparent {
    from {
      background-color: #fff;
    }
    to {
      background-color: transparent;
    }
  }

  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: unset;
    transform: translateX(100%);
    border-radius: 0 0 0 10px;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: auto;
    width: auto;
    z-index: 1200;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }
  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid {
    display: block;
  }

  #zona_hamburger {
    padding-right: 30px;
  }
  #opciones_desktop {
    display: none;
    visibility: hidden;
  }
  #zona_usuarios {
    display: none;
    visibility: hidden;
  }
  nav li a {
    color: $gray-2;
    text-decoration: none;
    &:hover {
      color: $blue !important;
      border-bottom: $blue solid 5px;
      padding-bottom: 3.5vh;
      text-decoration: none;
      .h4 {
        color: $blue !important;
      }

    }
    &:focus {
      border-bottom: $blue solid 5px !important;
      padding-bottom: 3.5vh;
      text-decoration: none;
    }

  }
  .h4 {
    color: $blue;
    text-decoration: none;
    font-size: 0.95rem;
  }
}

@media screen and(max-width:425px) {
  #zona_hamburger {
    padding-right: 15px !important;
  }
}

@include media-breakpoint-only(md) {
    @keyframes fadeToWhite {
    from {
      background-color: transparent;
    }
    to {
      background-color: #fff;
    }
}

  @keyframes fadeToTransparent {
    from {
      background-color: #fff;
    }
    to {
      background-color: transparent;
    }
  }

  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: unset;
    transform: translateX(100%);
    border-radius: 0 0 0 10px;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: auto;
    width: auto;
    z-index: 1200;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }
  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid {
    display: block;
  }
  #logoMenu {
    // padding-left: 50px;
  }
  // #zona_hamburger {
  //   padding-right: 30px;
  // }
  #opciones_desktop {
    display: none;
    visibility: hidden;
  }
  #zona_usuarios {
    display: none;
    visibility: hidden;
  }
  nav li a {
    color: $gray-2;
    text-decoration: none;
    &:hover {
      color: $blue !important;
      border-bottom: $blue solid 5px;
      padding-bottom: 3.5vh;
      text-decoration: none;
      .h4 {
        color: $blue !important;
      }
    }
    &:focus {
      border-bottom: $blue solid 5px !important;
      padding-bottom: 3.5vh;
      text-decoration: none;
    }
  }
  .h4 {
    color: #333 !important;
    font-size: 0.95rem;
  }
}

// Pantallas lg y xxl 992px a 1199px
@include media-breakpoint-between(lg, xxl) {
  .top-navbar-container {
      width: 55%;
    }

  #opciones_desktop {
    ul {
      text-align: left;
    }
  }
  
  @keyframes fadeToWhite {
    from {
      background-color: transparent;
    }
    to {
      background-color: #fff;
    }
  }

  @keyframes fadeToTransparent {
    from {
      background-color: #fff;
    }
    to {
      background-color: transparent;
    }
  }

  #navbar_main {
    display: none;
  }
  #zona_hamburger {
    display: none;
    visibility: hidden;
  }

  #zona_usuarios {
    margin-right: -3px;
    border-radius: 30px;
  }

  nav{
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 10000;
  }

  nav li a {
    padding: 23px !important;
    color: $blue;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      color: $blue !important;
      border-bottom: $blue solid 5px;
      padding-bottom: 3.5vh;
      text-decoration: none;
      .h4 {
        color: $blue !important;
      }
    }
    &:focus {
      border-bottom: $blue solid 5px !important;
      padding-bottom: 3.5vh;
      text-decoration: none;
    }
  }
  .btn-outline-warning {
    color: $blue !important;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: transparent;
    animation: fadeToTransparent 1.5s;
    animation-fill-mode: forwards;
    padding-right: 10px;
    padding-left: 10px;
    width: 217px;
    height: 46px;
    padding-top: 10px;
    margin-right: 3.4vw;
    border-radius: 30px;
    color: #004680;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 165.6%; /* 26.496px */
  }

  .btn-outline-warning:hover {
    background-color: $dark-blue !important;
    color: $white !important;
  }

  .btn-outline-warning:active {
    background-color: $blue !important;
    color: $white !important;
  }
}

</style>