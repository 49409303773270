<template>
    <!-- Input que consta de un titulo y los botones con texto y valor personalizable  -->
    <p class="text-center bold-text button-question">
        {{ titulo }}
    </p>
    <div class="d-flex justify-content-center">
        <div class="mx-2" v-for="(option, key) in options" :key="key">
            <div class="card-button card mr-2" :class="{active: value === option.value}" @click="updateValue(option.value)">
                <div class="card-body">
                    <div class="text-center">
                      <span class="bold-text">{{ option.label }}</span>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <p class="text-danger mt-1 mb-0 pl-1 bold-text text-lg-center" v-if="errorFlag">
        Este campo es requerido
    </p>
</template>

<script>
    export default {
        name: 'ButtonsInput',
        props: {
            titulo: String,
            options: Array, // [ {value, label }, { value, label } ],
            updateValue: Function,
            errorFlag: Boolean,
            value: String
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";
    // Text
    .bold-text{
        font-family: 'Montserrat-Bold';
        font-weight: bold;
    }

    .card-button{
        border-radius: 10px;
        width: 250px;
        // max-width: 150px;
        box-shadow: 0 4px 6px rgba(0,0,0,.15);
        .card-body{
            padding: 1.25rem;
        }
        &:hover{
            cursor: pointer;
            background-color: $blue;
            span {
                color: $white
            }
        }
        span{
            font-size: 28px;
        }
    }

    .active {
        background-color: $blue;
        color: white;
        border: 2px solid rgba(196, 196, 196, 0.4);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    }

    .button-question{
        font-size: 30px;
        padding-bottom: 3rem;
    }

    @include media-breakpoint-down(lg){
        .button-question{
            font-size: 18px;
            padding-bottom: 1.25rem;
        }
        .card-button{
            width: 150px;
            .card-body{
                padding: 1rem;
            }
            span{
                font-size: 18px;
            }
        }
    }

    @include media-breakpoint-down(md){
        .button-question{
            padding-bottom: 0rem;
        }
    }

    @include media-breakpoint-down(sm){
        .card-button{
            width: 115px;

        }
    }
</style>