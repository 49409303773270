<template>
    <!-- Select de modelos -->
    <select :disabled="options.length < 1"
            class="py-md-3 py-2 form-control h-md-100 text-blue bold-text input-controller"
            v-model="modelo"
            :class="printClass()">
        <option disabled
                value="0">Selecciona modelo</option>
        <option v-for="m in options"
                :key="m.ids"
                :value="m.ids">{{ m.modelo }}</option>
    </select>
    <!-- Mensaje de error -->
    <p v-show="errorFlag"
       class="d-md-none text-danger mt-1 mb-0 pl-3 bold-text">
        Selecciona el modelo
    </p>
</template>

<script>
import axios from 'axios';
import store from "@/store";
import { mapState } from "vuex";

export default {
    name: 'SelectModelo',
    props: {
        errorFlag: Boolean,
        customClass: String
    },
    data() {
        return {
            options: [],
            modelo: 0,
        }
    },
    store,
    computed: mapState({
        marca: (state) => state?.formulario?.data_matricula?.marca,
    }),
    methods: {
        reinitSelector: function () {
            // Se eliminan las opciones y se mantiene el placeholder en el select
            this.options = [];
            this.modelo = 0;
        },
        getModelos: async function () {
            await axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "mexico/getModelos/" + this.marca).then((resp) => {
                // API que obtiene los modelos para las marcas
                this.options = resp.data;
            }).catch(() => {
                // En caso de error, entonces se reinicia el selector
                this.reinitSelector();
            })
        },
        printClass: function () {
            // En caso de contar con una customClass como input, entonces se concatena con los valores de error o tiene valor.
            let customClass = '';
            if (this.errorFlag) {
                customClass += 'is-invalid '
            }
            if (this.marca !== 0 && this.marca) {
                customClass += 'has-value '
            }
            if (this.customClass) {
                customClass += this.customClass
            }
            return customClass;
        }
    },
    watch: {
        'marca': function (value) {
            // Si detecta un cambio en la marca, entonces debe reinciar el selector
            this.reinitSelector();
            if (value) {
                // Si obtiene un valor de marca, entonces se deben llamar a la API para obtener los modelos
                this.getModelos()
            }
        },
        'modelo': function (value) {
            // Se actiualiza el valor del modelo y año se actualiza a 0 en el store
            store.commit('setModelo', value)
            store.commit('setAno', 0);
            const elementoFiltrado = this.options.filter(
                element => element.ids == this.modelo
            );
            if (!elementoFiltrado?.length) {
                this.modelo = 0;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.form-control {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    height: auto;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../../assets/img/Vector.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-clip: content-box;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
    background-color: $white;
    border-width: 2px;
    color: #999;

    &:disabled {
        border-radius: 0.625rem !important;
        border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40)) !important;
        background: rgba(196, 196, 196, 0.30) !important;
        color: rgba(153, 153, 153, 0.60);
        cursor: not-allowed;

    }

    &.is-invalid {
        background-size: auto;
        background-clip: padding-box;
    }

    min-height: 48px;
}

.bold-text {
    font-family: 'Montserrat-Bold';
    font-weight: bold;
}

.bg-white {
    background-color: white;
    background-size: auto;
    background-clip: padding-box;
}

@include media-breakpoint-up(lg) {
    .form-control {

        color: #004680 !important;
        background-color: white;

        &:disabled {
            background-color: white !important;

        }
    }

}
</style>