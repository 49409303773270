<template>
    <!-- Elemento reutilizable que tiene el select del Año de Matriculación -->
    <select :disabled="options.length < 1"
            class="py-md-3 py-2 form-control h-md-100 text-blue bold-text"
            v-model="ano"
            :class="printClass()">
        <option disabled
                value="0">Año</option>
        <option v-for="m in options"
                :key="m"
                :value="m.value">{{ m.text }}</option>
    </select>
    <!-- Mensaje de error -->
    <p v-show="errorFlag"
       class="d-md-none text-danger mt-1 mb-0 pl-3 bold-text">
        Año de matriculación
    </p>
</template>

<script>
import axios from 'axios';
import store from "@/store";
import { mapState } from "vuex";

export default {
    name: 'SelectAnoMatriculacion',
    props: {
        errorFlag: Boolean,
        customClass: String
    },
    data() {
        return {
            options: [],
            ano: 0,
        }
    },
    store,
    computed: mapState({
        modelo: (state) => state?.formulario?.data_matricula?.modelo
    }),
    methods: {
        reinitSelector: function () {
            // Se eliminan las opciones y se mantiene el placeholder en el select
            this.options = [];
            this.ano = 0;
        },
        getLimitsAnios: async function () {
            // Llamada para obtener los años disponibles para las veriones
            await axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/getLimitsAnios`, { modelo: this.modelo }).then((resp) => {
                // Se obtienen los años en los que el auto estuvo disponible dentro del cataálogo de autometrica
                const { years } = resp.data;
                const currentYear = new Date().getFullYear();
                const limitAutometrica = 12; // Autometrica solo considera 12 años
                const limitYear = currentYear - limitAutometrica;
                const limit = 2005; // Se está considerando como el año mínimo el 2005
                const catalogo = [];
                for (let index = limitYear; index > limit; index--) {
                    catalogo.push(index.toString())
                }
                // Se usa set para evitar duplicados
                // Se concatena los años mandados por API y los años de nuestro catalogo de año mínimo hasta 2005
                this.options = [...new Set(years.concat(catalogo))];
                this.options.sort((a, b) => b - a);
                // Se necesita ordenar de mayor a menor para el select
                this.options = this.options.map(element => {
                    return {
                        value: element.toString(),
                        text: element.toString()
                    }
                })
                this.options.push({
                    value: limit,
                    text: `${limit} o anterior`
                })

            });
        },
        printClass: function () {
            // En caso de contar con una customClass como input, entonces se concatena con los valores de error o tiene valor.
            let customClass = '';
            if (this.errorFlag) {
                customClass += 'is-invalid '
            }
            if (this.ano !== 0 && this.ano) {
                customClass += 'has-value '
            }
            if (this.customClass) {
                customClass += this.customClass
            }
            return customClass;
        }
    },
    watch: {
        'modelo': function (value) {
            // Si detecta un cambio en el modelo, entonces debe reinciar el selector
            this.reinitSelector();
            if (value) {
                // Si obtiene un valor de modelo, entonces se deben llamar a la API para obtener los años que se puede generar
                this.getLimitsAnios()
            }
        },
        'ano': function (value) {
            // Se actiualiza el valor del año en el store
            store.commit('setAno', value)
            const elementoFiltrado = this.options.filter(
                element => element.value == this.ano
            );
            if (!elementoFiltrado?.length) {
                this.ano = 0;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.form-control {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    height: auto;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../../assets/img/Vector.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-clip: content-box;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
    background-color: $white;
    border-width: 2px;

    &:disabled {
        border-radius: 0.625rem !important;
        border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40)) !important;
        background: rgba(196, 196, 196, 0.30) !important;
        color: rgba(153, 153, 153, 0.60);
        cursor: not-allowed;
    }

    &.is-invalid {
        background-size: auto;
        background-clip: padding-box;
    }

    &.has-value {
        color: #999;
    }

    min-height: 48px;
}

.bold-text {
    font-family: 'Montserrat-Bold';
    font-weight: bold;
}

.bg-white {
    background-color: white;
    background-size: auto;
    background-clip: padding-box;
}

@include media-breakpoint-up(lg) {
    .text-question {
        font-size: 30px;
        margin-bottom: 1.5rem !important;
    }

    .form-control {

        color: #004680 !important;
        background-color: white;

        &::placeholder {
            color: #004680;
        }

        &:disabled {
            background-color: white !important;
            cursor: not-allowed;

        }
    }
}
</style>