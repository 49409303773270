<template>
    <!-- Elemento reutilizable que tiene el input del Kilometraje -->
    <p :class="{ 'd-md-none': !forceLabel }"
       class="mb-2 text-center bold-text mb-1 text-question">
        ¿Cuántos Kilometros tiene?
    </p>
    <input type="text"
           :disabled="modelo === 0"
           :class="{ 'is-invalid': errorFlag }"
           class="form-control py-md-3 py-2 h-md-100 text-blue bold-text"
           placeholder="Kilometraje"
           v-model="kms" />
    <!-- Mensaje de error -->
    <p v-show="errorFlag"
       class="d-md-none text-danger mt-1 mb-0 pl-3 bold-text">
        Kilometraje incorrecto
    </p>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

const numberRegex = /^[0-9]*$/;

export default {
    name: 'InputKilometraje',
    data() {
        return {
            kms: null,
        }
    },
    props: {
        errorFlag: Boolean,
        forceLabel: Boolean,
        textClass: String
    },
    store,
    methods: {
    },
    computed: mapState({
        modelo: (state) => state?.formulario?.data_matricula?.marca,
    }),
    watch: {
        'kms': function (newValue) {
            let kmsAux = null;
            if (newValue) {
                if (!numberRegex.test(newValue)) {
                    if (newValue.includes('.') || newValue.includes(',')) {
                        newValue = newValue.split('.')[0].split(',')[0];
                    }
                    newValue = newValue.replace(/[^ 0-9]/g, '');
                    kmsAux = parseInt(newValue)
                } else {
                    kmsAux = parseInt(newValue)

                }

                if (`${kmsAux}`.length > 6) {
                    kmsAux = parseInt(`${kmsAux}`.slice(0, 6))
                }
            }

            this.kms = kmsAux
            store.commit("setKms", kmsAux);

        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.form-control {
    height: auto; 

    // height: 100%;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    color: #999;

    border-radius: 10px;

    min-height: 48px;

    &::placeholder {
        color: rgba(153, 153, 153, 0.6);
    }

    &:disabled {
        cursor: not-allowed;

    }
}

.is-invalid {
    border-width: 2px;
}

.bold-text {
    font-family: 'Montserrat-Bold';
    font-weight: bold;
}


@include media-breakpoint-up(lg) {
    .text-question {
        font-size: 30px;
        margin-bottom: 1.5rem !important;
    }

    .form-control {

        color: #004680 !important;
        background-color: white;

        &::placeholder{
            color: #004680;
        }

        &:disabled {
            background-color: white !important;
            cursor: not-allowed;

        }
    }
}
</style>