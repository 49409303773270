<template>
    <h2 class="title-h1 text-center mobile">Pasos para conseguir tu préstamo con garantia de auto</h2>
    <div class="container container-hidder">
        <div id="carouselExampleIndicators"
             class="carousel slide"
             data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators"
                    :data-slide-to="index"
                    class="active indicator"
                    v-for="(step, index) in steps"
                    v-bind:key="step.stepnumber"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item"
                     v-for="step in steps"
                     v-bind:key="step.image"
                     :class="step.isSpecial ? 'active' : ''">
                    <div class="d-block w-100 item-container">
                        <Step :stepNumber="step.stepnumber"
                              :title="step.title"
                              :image="step.image"
                              :text="step.text"
                              :buttonText="step.buttonText"
                              :isSpecial="step.isSpecial" />
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev"
               href="#carouselExampleIndicators"
               role="button"
               data-slide="prev">
                <span class="carousel-control-prev-icon"
                      aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next"
               href="#carouselExampleIndicators"
               role="button"
               data-slide="next">
                <span class="carousel-control-next-icon"
                      aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

    <div class="container-background">
        <h2 class="title-h1 text-center desktop">Pasos para conseguir tu préstamo con garantia de auto</h2>
        <div class="container desktop-carousel">
            <div v-for="step in steps"
                 v-bind:key="step.image"
                 :class="step.isSpecial ? 'active' : ''">
                <div class="item-container">
                    <Step :stepNumber="step.stepnumber"
                          :title="step.title"
                          :image="step.image"
                          :text="step.text"
                          :buttonText="step.buttonText"
                          :isSpecial="step.isSpecial" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Step from './Step.vue';

export default {
    name: "BeneficiosIbancarCarousel",
    components: {
        Step,
    },
    data() {
        return {
            steps: [
                {
                    stepnumber: 1,
                    title: 'Solicitud',
                    image: 'step-1.png',
                    text: 'Rellena la solicitud y preaprobamos el préstamo al instante.',
                    buttonText: 'Solicitar',
                    isSpecial: true
                }
                , {
                    stepnumber: 2,
                    title: 'Documentación',
                    image: 'Agente.png',
                    text: 'Envía la documentación a tu ejecutivo personal para avanzar.',
                    buttonText: 'Ver documentación',
                    isSpecial: false
                }
                , {
                    stepnumber: 3,
                    title: 'Revision Vehículo',
                    image: 'calendar.png',
                    text: 'Programamos visita presencial para revisar el vehículo y recoger la factura. <p></p> Un profesional revisará el vehículo, 100% seguro.',
                    buttonText: null,
                    isSpecial: false
                }, {
                    stepnumber: 4,
                    title: 'Préstamo recibido',
                    image: 'telefono-tarjeta.png',
                    text: 'Recibe tu préstamo en cuestión de horas en tu cuenta bancaria.',
                    buttonText: null,
                    isSpecial: false
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.desktop {
    display: none;
}

.title-h1 {
    color: #004680 !important;
    font-family: 'Montserrat-Bold';
    margin: 0rem 0rem 2rem 0rem;
    padding: 0 1rem;
    font-size: 31px;
    // font-weight: 700;
}

@include media-breakpoint-down(md) {

    .title-h1 {
        font-size: 1.2rem;
        font-weight: bold;

        font-family: 'Montserrat-Bold';
        color: #004680 !important;
        text-align: center;

        /* Mobile 360 px/H2 */
        // font-style: normal;
        // font-weight: 700;
        line-height: normal;
    }
}

.carousel-item {
    width: 18rem;
}

#carouselExampleIndicators {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-solicitar {
    border-radius: 30px;
    padding: 10px 65px;
    min-width: 250px;
    font-family: 'Montserrat-Bold';
}

.carousel-inner {
    overflow: visible;
    width: 85%;
    width: 17rem;
}

.background-orange {
    background-color: $orange;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}


.text-white {
    font-size: 1.5rem;
}

s .image {
    height: 320px;
}

.container-background {
    padding: 2rem 0rem;

}

.desktop-carousel {
    display: none;
}

.container-hidder {
    //background-color: #ffa5000f;
    padding: 2rem;
}

@media (max-width: 1220px) {
    .desktop-carousel {
        gap: 1.8rem !important;
    }
}

@media screen and(max-width:1024px) {
    .desktop-carousel {
        flex-wrap: wrap;
    }
}

.mobile {
    font-size: 31px;
    padding: 0rem 2.5rem;
}

@include media-breakpoint-down(sm) {
    .mobile {
    font-size: 18px;
    padding: 0rem 1.5rem;
}
}



@include media-breakpoint-up(lg) {

    .desktop-carousel {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .container-hidder {
        display: none;
    }

    .desktop {
        display: block;
    }

    .title-h1.mobile {
        display: none;
    }

    .container-background {
        background-color: #ffa5000f;
    }

    .item-container {
        max-width: 16rem !important;
    }
}

@include media-breakpoint-only(lg) {
    .desktop-carousel{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: center; 
        gap: 2.5rem !important;
        padding: 0rem 7rem;
    }
}

.item-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
    // max-width: 16rem !important;
}

.container {
    margin-top: 2rem;
}

.carousel-inner {
    height: fit-content;
}

.indicator {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #D8D8D8;
}

.carousel-indicators {
    bottom: -4rem;

    .active {
        background-color: #004680;
    }
}
</style>