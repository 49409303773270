<template>
  <div class="button-position" id="whatsapp-button-float" v-if="!preload" style="position: fixed;
    margin-top: 1rem;
    margin-left: 0rem;">
    <div class="d-flex flex-column justify-content-center align-items-center mr-0 ">
      <a href="https://api.whatsapp.com/send?l=es&phone=5215612673878&text=¡Hola!%20Solicito%20información%20sobre%20el%20préstamo%20con%20garantía%20de%20auto.%20Gracias." 
      rel="nofollow noopener" target="_blank"><img class="p-1" src="@/assets/img/whatssap-button.svg" alt="Ibancar Whatssap" /></a>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "WhatssapButton",
  store,
  computed: mapState({
    preload: (state) => state.preload,
  }),
};
</script>

<style lang="scss">
.button-position {
  position: fixed;
  z-index: 10000;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "@/assets/styles/variables.scss";

/** NAVIGATION BAR */
@include media-breakpoint-between(xs, sm) {
  .button-position {
    left: 80%;
    top: 90%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    img{
      height: 75px;
      width: 75px;
    }
  }
}
@include media-breakpoint-only(md) {
  .button-position {
    left: 90.5%;
    top: 92%;
  }
}
@include media-breakpoint-only(lg) {
  .button-position {
    left: 93.5%;
    top: 94%;
  }
}
@include media-breakpoint-between(xl, xxl) {
  .button-position {
    left: 95.5%;
    top: 90%;
  }
}
</style>
