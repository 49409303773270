<template>
  <div class="page">
    <div class="row justify-content-center pb-3">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-4 col-xxl-4">
            <div id="masInformacion" class="card">
                    <div class="card-body">
                      <div class="d-flex flex-column justify-content-center align-items-center">
                          <h2 class="pb-3"><strong>404</strong></h2>
                          <h4 class="card-text text-center">
                            Ops, por algún motivo, la página que estás buscando no está disponible.
                          </h4>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <div>
                          <button type="button" class="btn-orange mt-4 mb-3" @click="goTo('/','')">Volver al inicio</button>
                        </div>
                      </div>
                    </div>
              </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "NotFound01",
    methods: {
      goTo(route,hash) {
        this.$router.push({path: route, hash: hash});
      },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
// Resposive para pantallas a partir de tamaño medio a pequeño

  .page {
    h2 {
      font-size: 3.5rem;
    }
    .card {
      color: $orange;
      border-style: none;
      .card-footer {
        background-color: transparent;
        border-style: none;
      }
    }
  }

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .page {
    margin-top: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 15%;
  }
}
@include media-breakpoint-between(md,lg) {
  .page {
    margin-top: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 15%;
  }
}
@include media-breakpoint-between(xl,xxl) {
  .page {
    margin-top: 4%;
    padding-top: 5%;
    margin-bottom: 4%;
  }
}


</style>