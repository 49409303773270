import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'

// JS
import 'bootstrap'
// CSS
import './assets/styles/app.scss'
// Titles AutoRefresh
import titleMixin from '../titleMixin'

const metaManager = createMetaManager();
const app = createApp(App).use(store).use(router).use(metaManager);

app.mixin(titleMixin);
app.mount('#app');
