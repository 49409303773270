<template>
  <div class="loading-container">
    <div class="logo-container d-flex align-items-center justify-content-center">
      <div class="animate_logo logo text-center">
        <img src="@/assets/img/IBLogo.svg" alt="logo" />
        <div class="col">
          <p class="text-center text-white font-weight-bold">Estamos calculando <br />tu préstamo</p>
        </div>
      </div>
    </div>
    <div class="ellipse text-center d-flex align-items-center justify-content-center">
      <div class="row justify-content-center animate balls" >
        <div class="col bolitas">
          <img src="@/assets/img/green_ok.svg" alt="ok" />
          <p class="texto-azul">Seguro</p>
        </div>
        <div class="col bolitas">
          <img src="@/assets/img/green_ok.svg" alt="ok" />
          <p class="texto-azul">Transparente</p>
        </div>
        <div class="col bolitas">
          <img src="@/assets/img/green_ok.svg" alt="ok" />
          <p class="texto-azul">Rápido</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Loading",
    components: {},
  };
</script>

<style lang="scss" scoped>
  @import "bootstrap/scss/_functions.scss";
  @import "bootstrap/scss/_variables.scss";
  @import "bootstrap/scss/_mixins.scss";
  @import "@/assets/styles/variables.scss";

  .loading-container{
    background-color: $blue;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1025;
  }

  .logo-container{
    height: 70vh;
  }

  .ellipse {
    border-top-left-radius: 100% 100px;
    border-top-right-radius: 100% 100px;
    background-color: white;
    height: 30vh;
  }
  
  .balls{
    width: 70%;
  }

  @keyframes animation1 {
    50%,
    75% {
      transform: scale(1.2);
    }
  }
  .animate_logo {
    animation: animation1 2s ease-out infinite;
  }

  .animate {
    .col {
      animation: animation1 2s ease-out infinite;
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          background: nth($colors, $i);
  
          &::before {
            animation-delay: $i * 0.2s;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md){

    @keyframes animation1 {
      50%,
      75% {
        transform: scale(1.1);
      }
    }

    .logo-container{
      height: 70%;
    }
    .ellipse {
      height: 30%;
    }
    .balls{
      width: 100%;
    }

    .bolitas {
      p {
        font-size: 0.9rem;
      }
    }
  }
</style>
