<template>
    <div class="table-container">
        <div class="table-title-container">
            <span>IBANCAR</span>
        </div>
        <div class="table-benefits-container">
            <div class="benefits-container">
                <p class="benefit">
                    <span class="text-orange">✓</span> Sigues manejando tu auto, sigue siendo tuyo
                </p>
                <p class="benefit">
                    <span class="text-orange">✓</span> Sin salir de casa, solicitud online y rápida
                </p>
                <p class="benefit">
                    <span class="text-orange">✓</span> Tendrás un ejecutivo personal asignado
                </p>
                <p class="benefit">
                    <span class="text-orange">✓</span> Mensualidades fijas, no hay pago final
                </p>
                <p class="benefit">
                    <span class="text-orange">✓</span> Abonas a capital desde la primera mensualidad
                </p>
                <p class="benefit">
                    <span class="text-orange">✓</span> Sin GPS
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TablaBeneficios",
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.table-container {
    border-radius: 0.6rem;
    border: 2px solid #D8D8D8;
    display: flex;
    flex-direction: column;
    background-color: white;

    flex-basis: 100%;

    .table-title-container {
        background-color: #004680;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
        border-bottom-right-radius: 0rem;
        border-bottom-left-radius: 0rem;
        padding: 1rem;
        height: 15%;

        span {
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            font-family: 'Montserrat-Bold';
        }

    }

    .benefits-container {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;


        .benefit {
            display: flex;
            align-items: center;
            color: #004680 !important;
            margin: 0rem 1rem;
            font-weight: 700;

            .text-orange {
                color: #FFA500;
                margin-right: 1rem;
                font-size: 1.5rem;
            }

        }

    }
}
</style>