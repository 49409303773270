import { createRouter, createWebHistory } from 'vue-router'
// TODO descomentar al quitar el coming soon
import Home from '../views/Home.vue'
// import Home from '../views/CoomingSoon.vue'
// 
import NotFound from '../views/NotFound.vue'
import Section1 from '@/components/Home/Section1.vue'

// import rutasBlog from './rutasblog.js'

const routes = [{
        path: '/',
        name: 'Su préstamo con garantía de auto',
        hash: '#tabla',
        component: Home,
        params: {
            title: 'Titulo home'
        },
        meta: {
            breadcrumb: [{
                name: 'Inicio'
            }]
        },
        children: [
            {
                path:'',
                components: {
                    default: Section1,
                    formulario: Section1
                }
            },
        ]
    },
    {
        path: '/home/',
        name: 'Home',
        hash: '#tabla',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    /*
    {
        path: '/credito-coche',
        name: 'CreditoCoche',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( webpackChunkName: "creditocoche"  '../views/CreditoCoche.vue'),
    },
    */
    {
        path: '/como-funciona',
        name: 'ComoFunciona',
        hash: '#documentacion',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "comofunciona" */ '../views/ComoFunciona.vue')
    },
    {
        path: '/como-funciona/escaner',
        name: 'Escaner',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "comofunciona" */ '../views/ComoFunciona.vue')
    },
    {
        path: '/quienes-somos',
        name: 'Quienes Somos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "quienessomos" */ '../views/QuienesSomos.vue'),
        meta: { transition: 'fade' }
    },
    {
        path: '/contacto',
        name: 'Contacto',
        hash: ['#contacta', '#enviarMensaje'],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "contacto" */ '../views/Contacto.vue')
    },
    {
        path: '/politica-privacidad',
        name: 'Privacidad',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "privacidad" */ '../components/Privacidad.vue')
    },
    {
        path: '/politica-cookies',
        name: 'Cookies',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "cookies" */ '../components/Cookies.vue')
    },
    {
        path: '/grupper-platform',
        name: 'Grupper',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "grupper" */ '../components/GrupperPlatform.vue')
    },
    
    /************************ FORMULARIO MEXICO ************************/
    {
        path: '/formulario/step1',
        name: 'Titular',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/Step1a.vue')
    },
    // {
    //     path: '/formulario/step1a',
    //     name: 'Factura auto',
    //     hash: '',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/Step2.vue')
    // },
    {
        path: '/formulario/step2',
        name: 'Datos vehiculo',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/Step1.vue')
    },
    // {
    //     path: '/formulario/step2a',
    //     name: 'Auto Pagado',
    //     hash: '',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/Step2a.vue')
    // },
    {
        path: '/formulario/step3',
        name: 'Datos Personales',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/Step3.vue')
    },
    {
        path: '/formulario/documentacion',
        name: 'Documentacion',
        hash: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/pre-aproval.vue')
    },
    {
        path: '/formulario/:codigo?',
        name: 'Formulario Mexico',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // Felicidades
        // NuevoFormularioSave
        component: () =>
            import ( /* webpackChunkName: "Documentacion" */ '../views/Felicidades.vue')
    },
/*
    {
        path: '/solicitud/coche/:codigo?',
        name: 'Fallo Matrícula Mexico',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "solicitud_denegada" */ /*'../components/NuevoFormulario/FalloAPI.vue')
    },
*/
    {
        path: '/solicitud/denegada/:codigo?',
        name: 'Solicitud cancelada Mexico',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "solicitud_denegada" */ '../components/FormularioMexico/SolicitudDenegada.vue')
    },
    {
        path: '/error',
        name: 'Error en la solicitud en México',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "solicitud_denegada" */ '../views/Error.vue')
    },
    
    /***************************************************************************** */
    /*
    {
        path: '/solicitud/coche/:codigo?',
        name: 'Fallo Matrícula',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "solicitud_denegada"  '../components/NuevoFormulario/FalloAPI.vue')
    },
*/
    {
        path: '/formulario/confirmacion',
        name: 'confirmacion',
        hash: '#newStepOk',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/NuevoSectionDocumentacion.vue')
    },
    {
        path: '/preaprobado',
        name: 'preaprobado',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "formulario" */ '../components/FormularioMexico/Preaprobado.vue')
    },
    /*
    {
        path:'/formulario/refuse/:refuse_message',
        name:'refuse',
        props: {refuse_message: true},
        component: () =>
            import ( /* webpackChunkName: "Documentacion" '../components/NuevoFormulario/Refuse.vue')
    },*/
    {
        path: '/credito-aval-coche-con-asnef',
        name: 'Crédito aval coche con Asnef',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "credito_aval_coche_con_asnef" */ '../views/CreditoAvalCocheConAsnef.vue')
    },
    {
        path: '/credito-con-aval',
        name: 'Crédito con aval',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        redirect: {name: 'CreditoCoche'}

    },
    {
        path: '/investor',
        name: 'Investor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "investor" */ '../components/Investor.vue')
    },

    {
        path: '/formulario-:codigo?',
        redirect: { name: "Formulario" }
    },
    {
        path: '/pagos/:tokenPago',
        redirect: to => ({
            path: "/php/pagos.php",
            query: { 'code': to.params.tokenPago },
        }),
    },
    // Landings
    {
        path: '/prestamo-garantia-auto',
        name: 'PrestamoGarantiaAuto',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "creditocoche" */ '../views/CreditoCoche.vue'),
    },
    {
        path: '/empena-tu-auto-y-continua-usandolo',
        name: 'EmpenaTuAutoContinuaUsandolo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "creditocoche" */ '../views/EmpenaTuAutoContinuaUsandolo.vue'),
    },
    {
        path: '/empeno-de-auto-en-mexico',
        name: 'EmpenoDeAutosEnMexico',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "creditocoche" */ '../views/EmpenoDeAutosEnMexico.vue'),
    },
    {
        path: '/empeno-de-autos-en-ibancar',
        name: 'EmpenoDeAutoEnIbancar',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "creditocoche" */ '../views/EmpenoDeAutoEnIbancar.vue'),
    },
    {
        path: '/prestamos-sobre-auto-en-ibancar',
        name: 'PrestamosSobreAutoEnIbancar',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "creditocoche" */ '../views/PrestamosSobreAutoEnIbancar.vue'),
    },
    //169
    {
        path: '/prestamo-factura-auto',
        name: 'PrestamoFacturaAutos',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "creditocoche" */ '../views/PrestamoFacturaAuto.vue'),
    },
    //170
    {
        path: '/prestamo-garantia-prendaria',
        name: 'PrestamoConGarantiaPrendaria',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "creditocoche" */ '../views/PrestamoConGarantiaPrendaria.vue'),
    },
    //171
    {
        path: '/prestamo-garantia-automotriz',
        name: 'PrestamoConGarantiaAutomotriz',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "creditocoche" */ '../views/PrestamoConGarantiaAutomotriz.vue'),
    },
    //172
    {
        path: '/prestamo-por-factura-auto',
        name: 'PrestamoPorFacturaAuto',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "creditocoche" */ '../views/PrestamoPorFacturaAuto.vue'),
    },
    //175
    {
        path: '/prestamo-con-garantia-de-auto-sin-dejarlo',
        name: 'PrestamoConGarantiaDeAutoSinDejarlo',
        hash: '#packServicios',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "creditocoche" */ '../views/PrestamoConGarantiaDeAutoSinDejarlo.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }
]

// rutasBlog.forEach((i)=> {
//   routes.unshift(i);
// })

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const position = {};
        if (to.hash) {
            switch (to.hash) {
                case '#packServicios':
                    position.offset = { y: 250 }
                    break;
                case '#startBlog':
                    return {
                        el: to.hash,
                        behavior: 'smooth'
                    }
                case '#tabla':
                    return {
                        el: to.hash,
                        behavior: 'smooth'
                    }
                default:
                    return {
                        el: to.hash,
                        behavior: 'smooth'
                    };
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0, behavior:'instant' }
        }
    },
})

router.beforeEach(
    (to, next) => {
        let documentTitle = `${to.name} | ${process.env.VUE_APP_TITLE}`
        if (to.params.title) {
            documentTitle = `${to.params.title} | ${process.env.VUE_APP_TITLE}`
        }
        document.title = documentTitle
        next
    }
);


export default router