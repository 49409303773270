<template>
    <div class="monto-form-container">
        <div class="select-title">
            <p class="question">¿Qúe monto necesitas?</p>
        </div>
        <div class="select-container">
            <p class="monto-amount">{{ montoFormatted }}</p>
            <div class="select-monto">
                <div class="max-container">
                    <div class="max-container-img" :onClick="handleMinAmount" style="cursor: pointer;">
                        <img src="@/assets/img/home/minusButton.svg"
                             alt=""
                             class="minus-button">
                    </div>
                </div>

                <input type="range"
                       class="form-range"
                       min=12000
                       max=120000
                       id="customRange"
                       step=1000
                       v-model.number="monto" />
                <div class="min-container">
                    <div class="min-container-img" :onClick="handleMaxAmount" style="cursor: pointer;">
                        <img src="@/assets/img/home/plusButton.svg"
                             alt=""
                             class="plus-button">
                    </div>
                </div>
            </div>
            <div class="min-max-container">
                <div class="max-container-numbers" >
                    <span>$12,000</span>
                </div>
                <div class="min-container-numbers" >
                    <span>$120,000</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
    name: 'SelectMonto',
    props: {
    },
    data() {
        return {
            monto: Number
        }
    },
    store,
    methods: {
        handleMinAmount(){
            if(parseInt(this.monto) == 12000){
                return;
            }
            this.monto = parseInt(this.monto) - 1000;
        },
        handleMaxAmount(){
            if(parseInt(this.monto) == 120000){
                return
            }
            this.monto = parseInt(this.monto) + 1000;
        }
    },
    computed: mapState({
        montoStored: (state) => parseInt(state?.formulario?.monto),
        montoFormatted: (state) => {
            let formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
                maximumFractionDigits: 0
            });
            return formatter.format(Number(state?.formulario?.monto));
        }
    }),

    watch: {
        'monto': function (value) {
            store.commit('setMonto', parseInt(value))
        }
    },
    mounted: function () {
        this.monto = parseInt(this.$store.state.formulario.monto)
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.min-max-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    color: #004680;
    font-family: 'Montserrat-Bold';
}

.question {
    display: none;
}


.max-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    span {
        color: #004680;
    }

    .max-container-img {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        img {
            width: 1.2rem;

        }
    }
}

.min-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    span {
        color: #004680;
    }

    .min-container-img {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        img {
            width: 1.2rem;

        }
    }
}

.monto-form-container {
    width: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.minus-button {
    height: 2rem;
}

.plus-button {
    height: 2rem;
}

.select-title {
    text-align: center;
    color: #004680;
    font-size: 1.5rem;
    font-family: 'Montserrat-Bold';
}

.select-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 75%;

    p {
        color: #004680;
        font-size: 3rem;
        font-weight: bold;
    }

    .monto-amount {
        font-size: 2rem;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat-Bold';
    }
}

.select-monto {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        width: 90%;
        background-color: #FFA500 !important;
        -webkit-appearance: none;
        border-radius: 1rem;
        height: 5px;
    }

    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
    }

}

@include media-breakpoint-up(lg) {

    .monto-form-container {
        flex-direction: column;
    }

    .select-title {
        color: white;

    }

    .select-container {
        p {
            color: white;
        }
    }

    .max-container-numbers {
        color: white;

    }

    .min-container-numbers {
        color: white;

    }


    .question {
        display: block;
    }

    .monto-amount {
        font-size: 3.5rem !important;
    }

    .max-container-img {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        img {
            width: 1.5rem !important;

        }
    }

    .min-container-img {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        img {
            width: 1.5rem !important ;

        }
    }

    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 2.8rem !important;
        width: 2.8rem !important;
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);

    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        border: none;
        height: 2.8rem !important;
        width: 2.8rem !important;
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
    }

}


@include media-breakpoint-down(md) {
    .monto-form-container{
        margin: 0rem 0rem 1rem 0rem;
    }

    
.select-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;

}
}
</style>