import { createStore } from 'vuex'
// Librería que permite que los datos de vuex persistan aunque se refresque la pagina. Se añade al final del archivo como plugin. ver abajo
// mas info en: https://github.com/robinvdvleuten/vuex-persistedstate#install
import createPersistedState from "vuex-persistedstate";

export default createStore({
	state: {
		formulario:{
			dinero: 50000,
			ingresos: '',
			financiacion: '',
			codigo_postal: '',
			estado: '',
			monto:20000,
			titular: 0,
			acepto1: false,
			acepto2: false,
            kms: '',
			matricula: [],
            nombre: '',
            dni_nif: '',
            email: '',
            telefono: '',
			marca:'',
			datos: {
				// Aviso e información -> checkboxes
				legal_advise: null,
				lopd: null
			},
			data_matricula: {
				bastidor: "", 
				marca: 0, 
				modelo: 0, 
				version: 0,
				potencia: "",
				combustible: "", 
				transmision: null, 
				fecha_matriculacion: 0,
				puertas: "",
			},
			// Monto y coche preaprobado
			preaprobado: {
				monto: 0,
				coche: ''
			},
			step: 1,
			loading: false,
			url_origen: window.location.href,
		},
		api_errors: {
			financiado: false,
			titular: false,
			ingresos: false
		},
		afiliado: null,
		stepsAPI: 1,
		stepsFallo: 1,
		api_token: '',
		token_enlace: '',
		id_solicitud: '',
		max_prestamo: '',
		solicitud:{

		},
		section1: {
			title: '',
			benefits: []
		},
		checkcolor1: 'azul',
		checkcolor2: 'blanco',
		checkcolor3: 'azul',
		barracolor1: 'blanco',
		barracolor2: 'azul',
		blog: {
			id: null
		},
		preload: false
	},
	mutations: {
		// Setter para los valores de preaprobado
		setPreaprobadoCoche(state, coche){
			state.formulario.preaprobado.coche = coche
		},
		setPreaprobadoMonto(state, monto){
			state.formulario.preaprobado.monto = monto
		},
		setAfiliado(state, afiliado) {
			state.afiliado = afiliado
		},
		setPreload(state, preload) {
			state.preload = preload
		},
		setBlogId(state, id) {
			state.blog.id = id
		},
		setStepAPI(state, id) {
			state.stepsAPI = id
		},
		setStepFallo(state, id) {
			state.stepsFallo = id
		},
		setDataMatricula(state, data){
			state.formulario.data_matricula = data;
		},
		// Setter para los valores de checkboxes
		setLegalAdvise(state, data){
			state.formulario.datos.legal_advise = data;
		},
		setLopd(state, data){
			state.formulario.datos.lopd = data;
		},
		setMarca(state, data){
			state.formulario.data_matricula.marca = data;
		},
		setMonto(state, data){
			state.formulario.monto = data;
		},
		setModelo(state, data){
			state.formulario.data_matricula.modelo = data;
		},
		setAno(state, data){
			state.formulario.data_matricula.fecha_matriculacion = data;
		},
		setVersion(state, data){
			state.formulario.data_matricula.version = data;
		},
		setStep(state, step){
			state.formulario.step = step;
		},
		setApiToken(state, token){
			state.api_token = token;
		},
		setFinanciacion(state, financiacion){
			state.formulario.financiacion = financiacion;
		},
		setKms(state, kms){
			state.formulario.kms = kms;
		},
		setNombre(state, nombre) {
			state.formulario.nombre = nombre;
		},
		setDNI(state, dni) {
			state.formulario.dni_nif = dni;
		},
		setEmail(state, email) {
			state.formulario.email = email;
		},
		setTelefono(state, telefono) {
			state.formulario.telefono = telefono;
		},
		setUbicacion(state, ubicacion) {
			state.formulario.ubicacion = ubicacion;
		},
		setCodigoPostal(state, codigo_postal) {
			state.formulario.codigo_postal = codigo_postal;
		},
		setEstado(state, estado) {
			state.formulario.estado = estado;
		},
		setTitular(state, titular) {
			state.formulario.titular = titular;
		},
		setIngresos(state, ingresos) {
			state.formulario.ingresos = ingresos;
		},
		setChecksAcepto(state,acepto) {
			state.formulario.acepto1 = acepto;
			state.formulario.acepto2 = acepto;
		},
		setCheckAcepto1(state,acepto) {
			state.formulario.acepto1 = acepto;
		},
		setCheckAcepto2(state,acepto) {
			state.formulario.acepto2 = acepto;
		},
		setMatricula(state, matricula){
			state.formulario.matricula = matricula;
		},
		setTokenEnlace(state, tokenEnlace){
			state.token_enlace = tokenEnlace;
		},
		setIdSolicitud(state, id){
			state.id_solicitud = id;
		},
		setSolicitud(state, solicitud){
			state.solicitud = solicitud;
		},
		setMaximoPrestamo(state, cantidad){
			state.max_prestamo = cantidad;
		},
		setTitleSection1(state, title){
			state.section1.title = title;
		},
		setCheckColor1(state, color) {
			state.checkcolor1 = color;
		},
		setCheckColor2(state, color) {
			state.checkcolor2 = color;
		},
		setCheckColor3(state, color) {
			state.checkcolor3 = color;
		},
		setBarraColor1(state, color) {
			state.barracolor1 = color;
		},
		setBarraColor2(state, color) {
			state.barracolor2 = color;
		},
		setBarraColor3(state, color) {
			state.barracolor2 = color;
		},
        resetSolicitud(state){
            state.formulario.step = 1;
            state.formulario.financiacion = '';
            state.formulario.kms = '';
            state.formulario.matricula = [];
            state.formulario.nombre = '';
            state.formulario.dni_nif = '';
            state.formulario.email = '';
            state.formulario.telefono = '';
            state.formulario.codigo_postal = '';
            state.formulario.estado = '';
            state.formulario.titular = 0;
            state.formulario.ingresos = '';
            state.formulario.acepto1 = false;
            state.formulario.acepto2 = false;
			state.formulario.data_matricula.bastidor = "";
			state.formulario.data_matricula.marca = 0;
			state.formulario.data_matricula.modelo = 0;
			state.formulario.data_matricula.version = 0;
			state.formulario.data_matricula.potencia = "";
			state.formulario.data_matricula.combustible = "";
			state.formulario.data_matricula.transmision = null;
			state.formulario.data_matricula.fecha_matriculacion = 0;
			state.formulario.data_matricula.puertas = "";
			state.formulario.preaprobado.monto = null;
			state.formulario.preaprobado.coche = null;
			state.formulario.datos.legal_advise = null;
			state.formulario.datos.lopd = null;
        },
		setApiErrorFinanciado(state, financiado) {
			state.api_errors.financiado = financiado
		},
		setApiErrorTitular(state, titular) {
			state.api_errors.titular = titular
		},
		setApiErrorIngresos(state, ingresos) {
			state.api_errors.ingresos = ingresos
		},
	},
	getters:{
		getEnlaceSolicitud: state => {
			return '/formulario/' + state.token_enlace;
		},
		getTitleSection1: state => {
			return state.section1.title;
		},
		getBenefitsSection1: state => {
			return state.section1.benefits;
		},
		getBlogId: state => {
			return state.blog.id;
		},
		getPreload: state => {
			return state.preload;
		},
		getValuePreaprobado: state => {
			return state
		}
	},
	actions: {
	},
	modules: {
	},
	plugins: [createPersistedState({
		// storage: window.sessionStorage
	})],
})
