<template>
<div class="bg-azul">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body pt-2">
                        <div id="logotipo">
                            <img width="228" height="59" src="@/assets/img/logo_ibancar.svg" alt="Ibancar">
                        </div>  
                    </div>
                    <div class="card-footer desktop">
                        <img width="134" height="67" class="desktop" src="@/assets/img/profeco.png" alt="Ibancar">
                    </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12 text-center">
                <div class="card text-center pt-2">
                    <div class="card-body">
                        <a class="btn btn-footer" href="mailto:a1@ibancar.com">
                            <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="icon" d="M2.2 0.404785H19.8C20.3835 0.404785 20.9431 0.64227 21.3556 1.065C21.7682 1.48772 22 2.06106 22 2.65888V16.1835C22 16.7813 21.7682 17.3546 21.3556 17.7774C20.9431 18.2001 20.3835 18.4376 19.8 18.4376H2.2C0.979 18.4376 0 17.4232 0 16.1835V2.65888C0 1.40786 0.979 0.404785 2.2 0.404785ZM11 8.29413L19.8 2.65888H2.2L11 8.29413ZM2.2 16.1835H19.8V5.32999L11 10.954L2.2 5.32999V16.1835Z" fill="white"/>
                            </svg>
                            <span class="pl-2">a1@ibancar.com</span>
                        </a>
                    </div>
                </div>
                <div class="card text-center pt-2">
                    <div class="card-body">
                    <div class="d-flex flex-row justify-content-center">
                        
                        <div class="pl-4">
                        <a id="btn-linkedin" href="https://www.linkedin.com/company/ibancar-world/mycompany/" target="_blank" rel="nofollow noopener" aria-label="Linkedin">
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="icon" d="M44.4469 0H3.54375C1.58437 0 0 1.54688 0 3.45938V44.5312C0 46.4437 1.58437 48 3.54375 48H44.4469C46.4062 48 48 46.4438 48 44.5406V3.45938C48 1.54688 46.4062 0 44.4469 0ZM14.2406 40.9031H7.11563V17.9906H14.2406V40.9031ZM10.6781 14.8688C8.39062 14.8688 6.54375 13.0219 6.54375 10.7437C6.54375 8.46562 8.39062 6.61875 10.6781 6.61875C12.9563 6.61875 14.8031 8.46562 14.8031 10.7437C14.8031 13.0125 12.9563 14.8688 10.6781 14.8688ZM40.9031 40.9031H33.7875V29.7656C33.7875 27.1125 33.7406 23.6906 30.0844 23.6906C26.3812 23.6906 25.8187 26.5875 25.8187 29.5781V40.9031H18.7125V17.9906H25.5375V21.1219H25.6312C26.5781 19.3219 28.9031 17.4188 32.3625 17.4188C39.5719 17.4188 40.9031 22.1625 40.9031 28.3313V40.9031Z" fill="white"/>
                            </svg>
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg col-md-12 col-sm-12 col-xs-12">
                <div id="interestPages" class="card text-center pt-2">
                    <div class="card-body">
                        <p><a href="https://p2p.ibancar.com/" target="_blank">Ibancar P2P transparency site</a></p>
                        <div id="mobile" class="d-flex flex-row justify-content-center pt-3 pb-3" >
                            <img width="134" height="67" id="mobile" src="@/assets/img/profeco.png" alt="Ibancar">
                        </div>
                    </div>
                    <div class="card-footer text-muted">
                    <p></p>
                    </div>
                </div>
            </div>
  </div>
</div>
  </div>
<div class="container-fluid bg-gray">
  <div class="row align-items-center justify-content-center pt-3">
      <p class="copyright">Copyright © Ibancar 2021</p>
  </div>
</div>

</template>

<script>
import AvisoLegal from './AvisoLegal.vue';
export default {
    name: 'FootInvestor',
    components: AvisoLegal,
    data() {
        return {
            publicPath: process.env.BASE_URL
        }
    }
}
</script>

<style scoped lang="scss">

.card {
  background-color: transparent !important;
  border: none;
  color: #fff;
}
.card-text {
  text-align: left;
  color: #fff;
}
// Quitar fondos
.card-header, .card-body, .card-footer{
    background-color: rgba(0,0,0, 0%);
    border: none;
}
.bg-gray {
  color: #fff;
  font-size: 10px;
  background: linear-gradient(0deg, #18333D, #18333D), #003159;
}
#interestPages {
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
  }
  a:active {
    color: #fff;
  }
}

.btn-footer {
  border: 2px solid #fff;
  width: 216px;
  height: 45px;
  border-radius: 30px;
  font-weight: bolder;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover {
  border: 2px solid #fff;
  width: 216px;
  height: 45px;
  border-radius: 30px;
  font-weight: bolder;
  color: #004680;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-footer:hover .icon {
  fill:#004680;
}

#btn-linkedin:hover .icon { 
  fill:#0A66C2;
}
#btn-facebook:hover .icon {
  fill:#1877F2;
}

  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-down(md) {
  .bg-azul {
    z-index: -1;
    background-color: #003159;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
  }
  #logotipo {
    text-align: center;
  }
  #interestPages {
    padding-top: 25px;
    .card-header {
      background: none;
      border: none;
      padding: 0;
    }
    .card-body {
      padding: 0;
      border-bottom: 1px solid #fff;
    }
  }
  .desktop {
    display: none;
  }
  .copyright {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-between(lg, xl) {
    .bg-azul {
        z-index: -1;
        background-color: #003159;
        padding-top: 3rem;
        padding-left: 5.5rem;
        padding-right: 4rem;
        padding-bottom: 3rem;
    }
  #logotipo {
    text-align: left;
  }
  #interestPages {
        .card-header {
            background: none;
            border: none;
            padding: 0;
        }
        .card-body {
            padding: 0;
            border-bottom: 1px solid #fff;
        }
        #mobile {
            display: none;
        }
    }
}
</style>