<template>
    <!-- Boton con fondo naranja, permite agrega icono. Este elemento es reutilizable y la funcionalidad depende del padre -->
    <button class="btn btn-solicitar background-orange text-white"
            @click="clickButton">
        {{ text }}
        <img v-if="icon"
             src="@/assets/img/icons/chevron-right-white.svg"
             alt="Img icon"
             class="ml-3 img-icon" />
    </button>
</template>

<script>
export default {
    name: 'ButtonConfirm',
    props: {
        clickButton: Function,
        icon: Boolean,
        text: String
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.btn-solicitar {
    padding: 1rem 3rem;
    border-radius: 30px;
    font-size: 27px;
    font-family: 'Montserrat-Bold';
    line-height: 1;
}

.background-orange {
    background-color: $orange;
}

.img-icon {
    width: 16px;
}

@include media-breakpoint-up(md) {
    .btn-solicitar {
        width: 45%;
    }
}

@include media-breakpoint-down(md) {
    .btn-solicitar {
        font-size: 18px;
    }

    .img-icon {
        width: 10px;
    }

    .btn-solicitar {
        width: 78%;
        margin-bottom: 2rem;
    }
}
</style>