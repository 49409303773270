<template>
    <div class="step-container"
         :class="buttonText ? 'special-gap' : ''">
        <span class="step-number">
            {{ stepNumber }}
        </span>
        <div class="step-title mb-3">
            <p>{{ title }}</p>
        </div>
        <div class="step-image mb-3">
            <img :src="require(`@/assets/img/home/` + image)"
                 alt="step">
        </div>
        <div class="step-text ">
            <p v-html="text"></p>
        </div>
        <div class="step-button"
             v-if="buttonText">
            <button type="button"
                    class="orange-button"
                    v-if="isSpecial"
                    @click="goToBanner()"> {{ buttonText }}<img src="@/assets/img/home/chevron-right.png"
                     alt=""
                     srcset=""></button>
            <button type="button"
                    class="btn-transparent"
                    v-else
                    @click="goTo('ComoFunciona', '#documentacion')"> {{ buttonText }} </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Step',
    props: {
        stepNumber: Number,
        title: String,
        image: String,
        text: String,
        buttonText: {
            type: [String, null],
        },
        isSpecial: Boolean
    },
    methods: {
        goTo(route, hash) {
            this.$router.push({ name: route, hash:hash  });
        },
        goToBanner(){
            const element = document.getElementById("banner-form");
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.step-container {
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 329px;
    z-index: 1000;

    border-radius: 0.42206rem;
    background: #FFF;
    box-shadow: 0px 2.701px 2.701px 0px rgba(0, 0, 0, 0.25);

    .step-number {
        position: absolute;
        top: -2rem;
        left: -1rem;
        right: 0;
        bottom: 0;
        color: rgba(255, 165, 0, 0.58);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat-Bold';
        font-size: 4rem;
        font-style: normal;
        line-height: normal;
        z-index: -1;
    }

    .step-title {
        p {
            color: #004680;
            text-align: center;
            font-family: 'Montserrat-Bold';
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
        }
    }

    .step-text {
        align-items: center;
            display: flex;
            height: 100%;
        p {
            color: #004680;
            text-align: center;

            font-family: Montserrat;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
    }

    .step-button {
        width: 100%;

        .orange-button {
            background-color: #FFA500;
            border: none;
            color: white;
            font-family: 'Montserrat';
            border-radius: 1.2rem;
            font-weight: 600;
            padding: 0.5rem;
            width: 100%;


            img {
                margin-left: 1rem;
                padding-bottom: 1px;
                height: 16px;
            }

            span {
                font-size: 1rem;
                margin-left: 0.5rem;
            }
        }

        .btn-transparent {
            width: 100%;

            background-color: transparent;
            border: 1px solid #004680;
            color: #004680;
            font-family: 'Montserrat-bold';
            border-radius: 1.2rem;
            font-weight: 600;
            padding: 0.5rem;

        }
    }
}

@include media-breakpoint-up(lg) {
    .step-container {
        width: 330px;
    }
}
</style>