<template>
    <div class="mt-5 ">
        <StepsCarousel />
    </div>
</template>

<script>
import StepsCarousel from './StepsCarousel.vue';

export default {
    name: "Section2",
    components: {
        StepsCarousel
    }
};
</script>

<style scoped lang="scss">
.card {
    width: 18rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-text {
    text-align: center;
}

.extra-large-izq,
.extra-large-der {
    display: none;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";


.title-h1 {
    color: #004680 !important;
    font-weight: 700;
}

@include media-breakpoint-down(md) {
    .card-container {
        flex-wrap: wrap;
        padding: 0px !important;
    }

    .title-h1 {
        font-size: 1.2rem;
        font-weight: bold;

        font-family: 'Montserrat-Bold';
        color: #004680 !important;
        text-align: center;

        /* Mobile 360 px/H2 */
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    #hsteeper {
        display: none;
    }

    .card {
        width: 78%;
        border-radius: 10px;

        p {
            display: inline-flex;
            width: 70%;
            height: 100%;
            align-items: center;
            text-align: left;
        }

        svg {
            width: 30%;
            float: left;
            display: block;
        }
    }

    .card-text {
        font-size: 1.1rem;
        text-align: left;
    }

    .padding {
        padding-top: 0.25rem;
    }
}

@include media-breakpoint-between(lg, xl) {
    .card-container {
        padding-left: 4rem !important;
        padding-right: 4rem !important;

    }

    #vsteeper {
        display: none;
    }

    .padding {
        padding-top: 3rem;
    }

    .card {
        width: 60%;
        border-radius: 10px;

        p {
            display: inline-flex;
            width: 70%;
            height: 100%;
            align-items: center;
            text-align: left;
        }

        svg {
            width: 30%;
            float: left;
            display: block;
        }
    }
}

@media (min-width: 1900px) and (max-width: 2000px) {
    .extra-large-izq {
        display: block;
        margin-left: 1%;
    }

    .extra-large-der {
        display: block;
        margin-right: 1%;
    }
}

@media (min-width: 2000px) {
    .extra-large-izq {
        display: block;
        margin-left: 2%;
    }

    .extra-large-der {
        display: block;
        margin-right: 2%;
    }
}
</style>