<template>
  <div class="card-box py-md-3 mt-lg-0" :style="{'margin-left': windowWidth >= 1200 && windowWidth <= 1300 ? '-40px' : '0'}">
    <!-- Pantallas moviles -->
    <div class="row justify-content-center aling-content-center d-lg-none d-md-none">
      <div class="col-md-7 col-sm-10">
        <div class="row justify-content-center">
          <p class="box-title-h1 mb-0">{{ "$" + formatNumber(formulario.dinero) }}</p>
        </div>
        <div class="ml-4 mr-4">
          <div id="container_input_range" class="row col-12" style="margin-right: 10px;">
            <input type="range" min="12000" max="120000" step="1000" v-model="formulario.dinero"/>
          </div>
          <div class="row justify-content-between">
            <div class="col-2 text-center">
              <span class="btn_menos" @click="sumarDinero(-1000)"> - </span>
              <span class="euros font-weight-bold mt-1">$12,000</span>
            </div>
            <div class="col-8"></div>
            <div class="col-2 text-center" style="display: flex;flex-direction: column;align-items: flex-end;">
              <span class="btn_mas" @click="sumarDinero(1000)"> + </span>
              <span class="euros font-weight-bold mt-1">$120,000</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none mt-4">
        <router-link to="/formulario/step1" class="btn btn-orange btn-mobile text-center align-middle"> 
          <div class="d-flex justify-content-center align-items-center">
            <span><b>Solicitar préstamo</b></span>
          </div>
        </router-link>
      </div>
    </div>
    
    <!-- Pantallas tablets en adelante -->
    <div class="row justify-content-center align-content-center d-none d-md-flex">
      <div class="col-md-8">
        <p class="box-title-h1 md_form_res text-center">
          {{ "$" + formatNumber(formulario.dinero) }}
        </p>
        <div class="row" id="container_input_range">
          <div class="btn_menos_lg ml-3"  @click="sumarDinero(-1000)">
            <span class="btn_menos">
              -
            </span>
          </div>
          
          <input type="range" min="12000" max="120000" step="1000" v-model="formulario.dinero"/>
          
          <div class="btn_mas_lg mr-3"  @click="sumarDinero(1000)">
            <span class="btn_mas">
              +
            </span>
          </div>
        </div>

        <div class="d-flex justify-content-between mx-3 mt-1">
          <span class="euros font-weight-bold">$12,000</span>
          <span class="euros font-weight-bold">$120,000</span>
        </div>
      </div>

      <div class="col-md-4 centrar-form-lg align-items-center">
        <router-link to="/formulario/step1" class="btn btn-orange text-center align-middle">
          <div class="d-flex justify-content-center align-items-center">
            <span>Solicitar</span>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>
<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "FormularioMX",
  data() {
    return {};
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    data_matricula: (state) => state.formulario.data_matricula,
    title: (state) => state.section1.title,
    formulario: (state) => state.formulario,
  }),
  props: {
    keyWord: String,
    windowWidth: undefined
  },
  methods: {
    sumarDinero: function (suma) {
      let calculo = parseFloat(this.formulario.dinero) + suma;
      if (calculo >= 20000 && calculo <= 120000) {
        this.formulario.dinero = calculo;
      }
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  mounted() {
    this.formulario.dinero = 50000;
    store.commit("setTitleSection1", "<strong>La mejor solución para un crédito con tu coche</strong>");
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

.btn {
  font-family: 'Montserrat-Bold' !important;
}
.euros {
  font-family: 'Montserrat-Bold' !important;
}

.btn-mobile {
  height: 60px;
  font-family: 'Montserrat';
  font-weight: 700;
  letter-spacing: 0.03em;
  width: auto;
}
#container_input_range {
  position: relative;
  height: 25px;
  margin-top: 0px;

  input[type="range"] {
    width: calc(100% - 55px);
    position: absolute;
    left: 35px;
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    margin: 18px 0;

    &:focus {
      outline: none;
    }

    height: 4px;
    cursor: pointer;
    // background: #ffc864;
    background: $orange-2;
    border-radius: 20px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    // background: #ffc864;
    background: $orange-2;
    border-radius: 20px;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background: rgb(255, 200, 100);
    background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    position: sticky;
    margin-top: -18px;
  }

  input[type="range"]::-moz-range-thumb {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: rgb(255, 200, 100);
    background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -10px;
    border: transparent;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $orange-2;
  }
}

.btn_mas,
.btn_menos {
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  background: $orange-2;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 15px;
}

.btn_menos {
  left: 5px;
}

.btn_menos_lg {
  .btn_menos {
    height: 23px;
    width: 23px;
  }
  font-size: x-large;
  position: absolute;
  top: -10px;
  left: 1px;
  color: $blue;
}
.btn_mas_lg {
  .btn_mas {
    height: 23px;
    width: 23px;
    margin-left: 10px;
  }
  font-size: x-large;
  position: absolute;
  top: -10px;
  right: -1px;
  color: $blue;
}
.centrar-form-lg {
  display: flex;
  align-items: center;
}

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {

  .box-title-h1 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
  }
  .btn_menos {
    left: 15px;
  }
  .btn_mas,
  .btn_menos {
    position: absolute;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    background: $orange-2;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-size: 20px;

    background-color: $blue;
    margin-top: -15px;
  }
  .euros {
    margin-left: -10px;
  }

  .box-title-h2 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: bolder;
    font-size: 18px;
    margin-top: 20px;
  }

  .btn {
    font-size: 22px !important;
  }
  .euros {
    font-family: "Montserrat";
    font-size: 14px;
    color: #999999;
    line-height: 32px;
  }
  .minuto {
    font-family: 'Montserrat-Bold';
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #004680;
  }
}

@include media-breakpoint-between(sm, md) {
}

@include media-breakpoint-between(md, lg) {
  .btn {
    width: 220px;
  }
  .centrar-form-lg {
    align-items: end;
  }
  #container_input_range {
    position: relative;
    height: 25px;
    margin-top: 0px;

    input[type="range"] {
      width: calc(100% - 70px);

      margin: 14px 0;

      &:focus {
        outline: none;
      }

      height: 2px;
      cursor: pointer;
      // background: #ffc864;
      background: $orange-2;
      border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 1px;
    }
  }
  .card-box {
    background-color: $blue;
    width: 85%;
    height: auto;
    padding: 2vh 3vh 3.2vh 3vh;
    border: 3px solid $blue;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
  }
  .box-title-h1 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: $white;
  }
  .box-title-h2 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $white;
  }
  .btn {
    height: auto;
    font-size: 18px !important;
  }
  .euros {
    font-family: "Montserrat";
    font-size: 14px;
    color: #fff;
    line-height: 32px;
  }
  .md_form_res {
    font-family: "Montserrat";
    font-size: 59px;
    line-height: 60px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 994px) {
  .card-box {
    margin-top: 0px!important;
  }
}
@include media-breakpoint-between(lg, xl) {
  .btn {
    width: 200px;
  }
  #container_input_range {
    position: relative;
    height: 25px;
    margin-top: 0px;

    input[type="range"] {
      width: calc(100% - 70px);
      margin: 16px 0;
      &:focus {
        outline: none;
      }
      height: 1px;
      cursor: pointer;
      background: $orange-2;
      border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 2px;
    }
  }
  .card-box {
    background-color: $blue;
    width: 675px;
    height: auto;
    padding: 1vh 0vh 5vh 1vh;
    border: 3px solid $blue;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    margin-top: 3vh;
  }
  .box-title-h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    color: $white;
  }

  .box-title-h2 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: $white;
  }
  .euros {
    font-family: "Montserrat";
    font-size: 14px;
    color: #fff;
    line-height: 32px;
  }
}
</style>
